/**
 * Formatea un número para mostrarlo.
 *
 * @param {number|string} input - el número de entrada o cadena
 * @return {string} el número formateado para mostrar
 */

export const formatNumber = (input, minimumFractionDigits = 2, maximumFractionDigits = 4) => {
  if (!input || input === undefined) return ''

  const num = typeof input === 'string' ? parseFloat(input) : input

  return num.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits
  })
}
